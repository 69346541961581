<template>
  <div class="serveBindingManage">
    <ds-header title="模块管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="所属项目">
        <a-tree-select
          v-model="searchForm.projectId"
          style="width: 200px"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择所属项目"
          allow-clear
          tree-default-expand-all
        >
          <a-tree-select-node :disabled="item.disabled" :value="item.id" :title="item.project_name" v-for="item in projectList" :key="item.id">
            <a-tree-select-node :key="arr.id" :value="arr.id" :title="arr.project_name" v-for="arr in item.child">
            </a-tree-select-node>
          </a-tree-select-node>
        </a-tree-select>
      </a-form-item>
      <a-form-item label="模块名称：">
        <a-input v-model.trim="searchForm.moduleName" placeholder="请输入模块名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="模块位置：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.pagePosition"
        >
          <a-select-option v-for="item in pagePositionList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addModular()"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="iconUrl" slot-scope="text, record" class="action">
          <div class="imgContainer">
            <img :src="record.iconUrl" v-if="record.iconUrl"/>
          </div>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="amend(record)"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="restart(record)">
            <span v-if="record.status == 1">启用</span>
            <span v-else>禁用</span>
          </a-button>
          <a-button class="btnClass" type="link" @click="del(record)"> 删除 </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import * as api from "@/api/modularManagement";

export default {
  name: "newModuleManagement",
  computed: {
    ...mapState({
      projectList: (state) => {
        return state.common.projects.length && state.common.projects.map(item => {
          return {
            ...item,
            disabled: !!(item.child)
          }
        })
      }
    })
  },
  data() {
    return {
      searchForm: {
        moduleName: undefined,
        projectId: undefined,
        pagePosition: undefined,
      },
      // 项目名称
      projectName: [],
      pagePositionList: [{id: 0, name: "首页"},{id: 1, name: "食住行"},{id: 2, name: "无忧创业"},{id: 3, name: "我的权益"},{id: 4, name: "积分商城"}],
      table: {
        dataInfo: [],
        columns: [
          {
            title: "所属项目",
            dataIndex: "projectName",
            key: "projectName",
          },
          {
            title: "模块图标",
            dataIndex: "iconUrl",
            key: "iconUrl",
            scopedSlots: { customRender: "iconUrl" },
            width: 112,
            align: "center",
          },
          {
            title: "模块名称",
            dataIndex: "moduleName",
            key: "moduleName",
          },
          {
            title: "页面位置",
            dataIndex: "pagePosition",
            customRender(text) {
              if(text === 0) {
                  return '首页'
              } else if(text === 1) {
                return '食住行'
              } else if(text === 2) {
                  return '无忧创业'
              } else if(text === 3) {
                  return '我的权益'
              } else {
                return '积分商城'
              }
            },
          },
          {
            title: "模块类型",
            dataIndex: "moduleType",
            customRender(text) {
              if (text === 0) {
                return '宫格模块'
              } else if (text === 1) {
                return '常用服务'
              } else if (text === 2) {
                return '一般服务'
              } else if (text === 3) {
                return 'banner'
              } else if (text === 4) {
                return '广告位'
              } else if (text === 5) {
                return '活动'
              } else if (text === 6) {
                return '权益'
              } else if(text === 7) {
                return '政策咨询'
              } else if(text ===8) {
                return '咨询管理'
              } else {
                return '-'
              }
            },
          },
          {
            title: "展示顺序",
            dataIndex: "sort",
            key: "sort",
          },
          {
            title: "状态",
            dataIndex: "status",
            key: "status",
            customRender(text) {
              return text == 1 ? "禁用" : "启用";
            },
          },
          {
            title: "更新时间",
            dataIndex: "gmtUpdate",
            key: "gmtUpdate",
            customRender(text) {
              return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "-";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "200px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  created() {
    this.searchFormList();
    // this.projectNameList();
  },
  methods: {
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 重制
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    // 新增
    addModular() {
      this.$router.push({
        path: "/newModuleManagement/add",
        query: {
          type: "add",
        },
      });
    },

    // 查看
    view(record) {
      var id = record.id;
      this.$router.push({
        path: "/newModuleManagement/add",
        query: {
          id: id,
          type: "view",
        },
      });
    },
    // 修改
    amend(record) {
      var id = record.id;
      this.$router.push({
        path: "/newModuleManagement/add",
        query: {
          id: id,
          type: "update",
        },
      });
    },

    // 启用禁用
    restart(record) {
      let that = this;
      if(record.status == 1) {
        var title = "确定启用该 【 "+record.moduleName+" 】 数据吗？"
        var status = 0;
      } else {
        var title = "确定禁用该 【 "+record.moduleName+" 】 数据吗？"
        var status = 1;
      }

      this.$confirm({
        title: title,
        onOk() {
          var id = record.id;
          api.newModularRestart(id, status).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.current = 1;
              that.searchFormList();
            } else {
              that.$message.error("操作失败");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    // 删除
    del(record) {
      let that = this;
      this.$confirm({
        title: "确定要删除该 【 "+record.moduleName+" 】 数据吗？",
        onOk() {
          var id = record.id;
          api.newModularDel(id).then((res) => {
            if (res.code === "200") {
              that.$message.success("操作成功");
              that.table.pagination.current = 1;
              that.searchFormList();
            } else {
              that.$message.error("操作失败");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },

    // 列表
    async searchFormList() {
      var data = {};
      data.moduleName = this.searchForm.moduleName
      data.projectId = this.searchForm.projectId
      data.pagePosition = this.searchForm.pagePosition
      data.page = this.table.pagination.current;
      data.size = this.table.pagination.pageSize
      const res = await api.newModularList(data);
      if (res.code == 200) {
        this.table.dataInfo = res.data.appModuleList;
        this.table.pagination.total = res.data.count;
      }

    },

    // 项目列表
    // async projectNameList() {
    //   const res = await api.projectName();
    //   if (res.code == 200) {
    //     this.projectName = res.data.items;
    //   }
    // },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.inputClass {
  width: 200px;
}
.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
